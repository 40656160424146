import React, { useState } from "react";
import Event1 from "../../images/Events/Electronics/e5.jpg";
import Event2 from "../../images/Events/Electronics/e3.jpg";
import Event3 from "../../images/Events/Electronics/e4.jpg";
import Event4 from "../../images/Events/Electronics/e1.jpg";
import Event5 from "../../images/Events/Electronics/ev2.jpg";
import Event6 from "../../images/Events/Electronics/ev3.jpg";
import Event7 from "../../images/Events/Electronics/ev4.jpg";
import Event8 from "../../images/Events/Electronics/ev1.jpg";
import k1 from "../../images/Events/Science/k1.jpg";
import k2 from "../../images/Events/Science/k2.jpg";
import k3 from "../../images/Events/Science/k3.jpg";
import k4 from "../../images/Events/Science/k4.jpg";
import he1 from "../../images/Events/Science/he1.jpg";
import he2 from "../../images/Events/Science/he2.jpg";
import he3 from "../../images/Events/Science/he3.jpg";
import he4 from "../../images/Events/Science/he4.jpg";
import ip1 from "../../images/Events/Science/ip1.jpg";
import ip2 from "../../images/Events/Science/ip2.jpg";
import ip3 from "../../images/Events/Science/ip3.jpg";
import ip4 from "../../images/Events/Science/ip4.jpg";
import ep1 from "../../images/Events/Science/ep1.jpg";
import ep2 from "../../images/Events/Science/ep2.jpg";
import ep3 from "../../images/Events/Science/ep3.jpg";
import { useParams } from "react-router-dom";

export default function DeptEventAnalysis() {
  const { branch } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const EventSection = ({ title, subtitle, images }) => (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-red-600 mb-2 text-center">{title}</h2>
      <p className="text-blue-600 text-center italic mb-6">{subtitle}</p>
      
      {/* Three images in top row */}
      <div className="flex justify-center gap-4 mb-4">
        {images.slice(0, 3).map((img, index) => (
          <div key={`top-${index}`} className="w-1/3 aspect-video overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <img
              src={img}
              alt={`Event ${index + 1}`}
              className="w-full h-full object-cover cursor-pointer hover:scale-105 transition-transform duration-300"
              onClick={() => openImage(img)}
            />
          </div>
        ))}
      </div>
      
      {/* One image in bottom row (centered) */}
      {images.length > 3 && (
        <div className="flex justify-center">
          <div className="w-1/3 aspect-video overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <img
              src={images[3]}
              alt="Event 4"
              className="w-full h-full object-cover cursor-pointer hover:scale-105 transition-transform duration-300"
              onClick={() => openImage(images[3])}
            />
          </div>
        </div>
      )}
    </div>
  );

  const NoEvents = () => (
    <div className="text-center py-12">
      <p className="text-red-500 text-2xl font-bold">No Events data available for this branch!</p>
    </div>
  );

  const DeptEventAnalysisData = {
    computer: {
      description: (
        <div className="space-y-12">
          <EventSection
            title='Interactive session on "Arduino IDE & development trainer kit & peripherals"'
            subtitle='by Mr. Nikhil Temburne (Alumni - 2022) | Date: 21th January 2025'
            images={[Event1, Event2, Event3, Event4]}
          />
          <EventSection
            title='Expert talk on "Electronics Development in Japan & Career Opportunities in Foreign Languages"'
            subtitle='by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan | Date: 05th October 2024'
            images={[Event8, Event5, Event6, Event7]}
          />
        </div>
      ),
    },
    civil: {
      description: (
        <div className="space-y-12">
          <EventSection
            title='Interactive session on "Arduino IDE & development trainer kit & peripherals"'
            subtitle='by Mr. Nikhil Temburne (Alumni - 2022) | Date: 21th January 2025'
            images={[Event1, Event2, Event3, Event4]}
          />
          <EventSection
            title='Expert talk on "Electronics Development in Japan & Career Opportunities in Foreign Languages"'
            subtitle='by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan | Date: 05th October 2024'
            images={[Event8, Event5, Event6, Event7]}
          />
        </div>
      ),
    },
    mechanical: {
      description: (
        <div className="space-y-12">
          <EventSection
            title='Interactive session on "Arduino IDE & development trainer kit & peripherals"'
            subtitle='by Mr. Nikhil Temburne (Alumni - 2022) | Date: 21th January 2025'
            images={[Event1, Event2, Event3, Event4]}
          />
          <EventSection
            title='Expert talk on "Electronics Development in Japan & Career Opportunities in Foreign Languages"'
            subtitle='by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan | Date: 05th October 2024'
            images={[Event8, Event5, Event6, Event7]}
          />
        </div>
      ),
    },
    electrical: {
      description: (
        <div className="space-y-12">
          <EventSection
            title='Interactive session on "Arduino IDE & development trainer kit & peripherals"'
            subtitle='by Mr. Nikhil Temburne (Alumni - 2022) | Date: 21th January 2025'
            images={[Event1, Event2, Event3, Event4]}
          />
          <EventSection
            title='Expert talk on "Electronics Development in Japan & Career Opportunities in Foreign Languages"'
            subtitle='by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan | Date: 05th October 2024'
            images={[Event8, Event5, Event6, Event7]}
          />
        </div>
      ),
    },
    electronics: {
      description: (
        <div className="space-y-12">
          <EventSection
            title='Interactive session on "Arduino IDE & development trainer kit & peripherals"'
            subtitle='by Mr. Nikhil Temburne (Alumni - 2022) | Date: 21th January 2025'
            images={[Event1, Event2, Event3, Event4]}
          />
          <EventSection
            title='Expert talk on "Electronics Development in Japan & Career Opportunities in Foreign Languages"'
            subtitle='by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan | Date: 05th October 2024'
            images={[Event8, Event5, Event6, Event7]}
          />
        </div>
      ),
    },
    workshop: {
      description: <NoEvents />,
    },
    applied_mechanics: {
      description: <NoEvents />,
    },
    science_humanities: {
      description: (
        <div className="space-y-12">
          <EventSection
            title="Karate Camp by Women Grivence Cell and NSS"
            subtitle="Date: 6th March 2025"
            images={[k1, k2, k3, k4]}
          />
          <EventSection
            title="Health Checkup Camp for Staff and Girls Students"
            subtitle="Date: 21th February 2024"
            images={[he1, he2, he3, he4]}
          />
          <EventSection
            title="Inauguration of Induction Program for first year Student and Teachers Day"
            subtitle="Date: 5th September 2024"
            images={[ip1, ip2, ip3, ip4]}
          />
          <EventSection
            title="Expert Lecturer on Science by Dr. Nagpurkar sir"
            subtitle="Date: 5th September 2024"
            images={[ep1, ep2, ep3]}
          />
        </div>
      ),
    },
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="heading heading-top-left-line"> Department Events</h1>

      {DeptEventAnalysisData[branch]?.description || <NoEvents />}

      {/* Image Modal */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 p-4"
          onClick={closeImage}
        >
          <div className="relative max-w-6xl w-full">
            <button
              className="absolute -top-12 right-0 text-white text-4xl font-bold hover:text-gray-300 transition-colors"
              onClick={closeImage}
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Enlarged view"
              className="max-w-full max-h-[90vh] mx-auto border-4 border-white rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
}