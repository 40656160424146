import React, { useState } from "react";
import { Slider } from "../components/Slider";

function GrievanceForm() {
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        address: "",
        phoneNumber: "",
        gender: "",
        grievanceCategory: "",
        branch: "",
        sessionOfAdmission: "",
        enrollmentNumber: "",
        semester: "",
        grievanceType: "",
        lastComplaintResolved: "",
        referenceNumber: "",
        grievanceDetails: "",
    });

    const [emailError, setEmailError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setFormData({ ...formData, email });
        setEmailError(validateEmail(email) ? "" : "Please enter a valid email address");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateEmail(formData.email)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        try {
            const response = await fetch(
                "https://gpsakoli.ac.in/public/api/connection?action=insertGrievanceDetails",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                alert("Grievance Submitted Successfully!");
                setFormData({
                    email: "",
                    name: "",
                    address: "",
                    phoneNumber: "",
                    gender: "",
                    grievanceCategory: "",
                    branch: "",
                    sessionOfAdmission: "",
                    enrollmentNumber: "",
                    semester: "",
                    grievanceType: "",
                    lastComplaintResolved: "",
                    referenceNumber: "",
                    grievanceDetails: "",
                });
            } else {
                alert("Submission failed. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again later.");
        }
    };

    return (
        <>
            <Slider />
            <div className="max-w-[80%] mx-auto p-4">
                <h1 className="heading text-center">
                Existing  <span className="heading-red">Students</span>
                </h1>
                <h1 className="heading heading-top-left-line">Student Grievance Form  </h1>
                <form className="text-left" onSubmit={handleSubmit}>
                    {/* Email */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Email *</label>
                        <input
                            className={`border p-2 w-full rounded ${emailError ? "border-red-500" : ""}`}
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleEmailChange}
                            required
                        />
                        {emailError && <p className="text-red-500">{emailError}</p>}
                    </div>

                    {/* Name */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Name *</label>
                        <input
                            required
                            className="border p-2 w-full rounded"
                            name="name"
                            type="text"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Address */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Address *</label>
                        <textarea
                            required
                            className="border p-2 w-full rounded"
                            name="address"
                            placeholder="Enter your address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Phone Number */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Phone Number *</label>
                        <input
                            required
                            className="border p-2 w-full rounded"
                            name="phoneNumber"
                            type="text"
                            placeholder="Enter your phone number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Gender */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Gender *</label>
                        <div className="flex gap-4">
                            <label>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Female"
                                    checked={formData.gender === "Female"}
                                    onChange={handleChange}
                                    required
                                />{" "}
                                Female
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Male"
                                    checked={formData.gender === "Male"}
                                    onChange={handleChange}
                                    required
                                />{" "}
                                Male
                            </label>
                        </div>
                    </div>

                    {/* Grievance Category */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Grievance Category *</label>
                        <select
                            required
                            className="border p-2 w-full rounded"
                            name="grievanceCategory"
                            value={formData.grievanceCategory}
                            onChange={handleChange}
                        >
                            <option value="">Select Category</option>
                            <option value="Employee">Employee</option>
                            <option value="Student">Student</option>
                            <option value="Alumni">Alumni</option>
                            <option value="Parents">Parents</option>
                            <option value="Hostel">Hostel</option>
                        </select>
                    </div>

                    {/* For Students Only */}
                    {formData.grievanceCategory === "Student" && (
                        <>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2">Branch *</label>
                                <select
                                    className="border p-2 w-full rounded"
                                    name="branch"
                                    value={formData.branch}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Branch</option>
                                    <option value="Civil Engineering">Civil Engineering</option>
                                    <option value="Mechanical Engineering">Mechanical Engineering</option>
                                    <option value="Electrical Engineering">Electrical Engineering</option>
                                    <option value="Electronics and Telecommunication Engineering">
                                        Electronics and Telecommunication Engineering
                                    </option>
                                    <option value="Computer Technology">Computer Technology</option>
                                </select>
                            </div>
                        </>
                    )}

                    {/* Grievance Type */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Grievance Type *</label>
                        <select
                            required
                            className="border p-2 w-full rounded"
                            name="grievanceType"
                            value={formData.grievanceType}
                            onChange={handleChange}
                        >
                            <option value="">Select Type</option>
                            <option value="Institutional">Institutional</option>
                            <option value="Departmental">Departmental</option>
                            <option value="Personal">Personal</option>
                            <option value="Parents">Parents</option>
                        </select>
                    </div>

                    {/* Grievance Details */}
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Grievance Details *</label>
                        <textarea
                            required
                            className="border p-2 w-full rounded"
                            name="grievanceDetails"
                            placeholder="Describe your grievance"
                            value={formData.grievanceDetails}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                        <button className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded w-full sm:w-auto">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default GrievanceForm;
