import React from "react";
import { Slider } from "../components/Slider";

function AlumniRegistrationForm() {
  const facultyData = [
    {
        serial: 1,
        facultyName: "Dr. P.B. Lahoti",
        designation: "Lecturer",
        department: "Computer Engineering",
        link: "https://scholar.google.co.in/citations?user=3StalTYAAAAJ&hl=en",
      },
    {
      serial: 2,
      facultyName: "Dr. D.J. Ghode",
      designation: "Lecturer",
      department: "Mechanical Engineering",
      link: "https://scholar.google.com/citations?user=EtoUhdoAAAAJ&hl=en",
    },

  ];

  return (
    <>
      <Slider />

      <div className="max-w-[80%] mx-auto p-4">
        <h1 className="heading text-center">
          Achievements <span className="heading-red">of GPS</span>
        </h1>
        <br />
        <h1 className="heading heading-top-left-line">
          Researches by Faculties in given link
        </h1>

        {/* Faculty Table */}
        <div className="mt-5">
          {facultyData.length > 0 ? (
            <table className="table-auto border-collapse border border-gray-300 w-full">
              <thead>
                <tr className="bg-red-600 text-white">
                  <th className="text-center border border-gray-300 px-4 py-2">Sr No.</th>
                  <th className="text-center border border-gray-300 px-4 py-2">Faculty Name</th>
                  <th className="text-center border border-gray-300 px-4 py-2">Designation</th>
                  <th className="text-center border border-gray-300 px-4 py-2">Department</th>
                  <th className="text-center border border-gray-300 px-4 py-2">More Info</th>
                </tr>
              </thead>
              <tbody>
                {facultyData.map((faculty) => (
                  <tr key={faculty.serial} className="hover:bg-gray-100">
                    <td className="text-center border border-gray-300 px-4 py-2">{faculty.serial}</td>
                    <td className="border border-gray-300 px-4 py-2">{faculty.facultyName}</td>
                    <td className="border border-gray-300 px-4 py-2">{faculty.designation}</td>
                    <td className="border border-gray-300 px-4 py-2">{faculty.department}</td>
                    <td className="text-center border border-gray-300 px-4 py-2">
                      <a
                        href={faculty.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline focus:outline-none"
                      >
                        Visit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-red-500 text-2xl font-bold text-center mt-4">
              No faculty data available !!
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default AlumniRegistrationForm;
