import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Slider } from '../components/Slider';
import { FiPlus, FiMinus } from "react-icons/fi"; // Import icons from react-icons



function AlumniRegistrationForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const [yearOfPassing, setYearOfPassing] = useState("");
    const [emailError, setEmailError] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [occupation, setOccupation] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [afterDiploma, setAfterDiploma] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setEmail(email);
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError("");
        }
    };

    const [openIndex, setOpenIndex] = useState(null);
    
        const norms = [
            
            {
                name: "Invitation of Alumni Program",
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//invitation%20card%20Alumni%20Meet-3.pdf",
            },
        ];
    
        const togglePDF = (index) => {
            setOpenIndex(openIndex === index ? null : index);
        };
    

    return (
        <>
            <Slider />
            <div className="   max-w-[80%] mx-auto p-4 " method="post" action="https://gpsakoli.ac.in/public/api/connection?action=insertAlumniDetails" onSubmit={handleSubmit}>
                <h1 className="heading text-center">Alumni <span className="heading-red">Association</span></h1>
                <br></br>
                <h1 className="heading heading-top-left-line">Programs Conducted </h1>
                    <ul className="space-y-3">
                        {norms.map((norm, index) => (
                            <li
                                key={index}
                                className="p-4 shadow-md "
                                style={{
                                    background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                                }}
                            >
                                <button
                                    onClick={() => togglePDF(index)}
                                    className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                                >
                                    <span> {norm.name}</span>
                                    {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                                </button>
           
                                {openIndex === index && (
                                    <div className="mt-3 border rounded-lg overflow-hidden">
                                        <iframe
                                            src={norm.url}
                                            width="100%"
                                            height="500px"
                                            className="border-none"
                                            title={norm.name}
                                        ></iframe>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
        </>
    );
}

export default AlumniRegistrationForm;




