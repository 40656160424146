import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi"; // Import icons from react-icons

export const EoaReports = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const norms = [
    {
      name: "Eoa Report 2020-21",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//EOA_Report_2020-21.PDF",
    },
    {
      name: "Eoa Report 2021-22",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//EOA%20Report%2021-22.PDF",
    },
    {
      name: "Eoa Report 2022-23",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//EOA%20Report%2022-23.PDF",
    },
    {
      name: "Eoa Report 2023-24",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//EOA-Report-2023-2024.PDF",
    },
    {
      name: "Eoa Report 2024-25",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//EOA%20Report%202024-25.PDF",
    },
  ];

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="heading heading-top-left-line">Eoa Reports</h1>
      <ul className="space-y-3">
        {norms.map((norm, index) => (
          <li
            key={index}
            className="p-4 shadow-md"
            style={{
              background:
                "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
            }}
          >
            <button
              onClick={() => togglePDF(index)}
              className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
            >
              <span>{norm.name}</span>
              {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
            </button>

            {openIndex === index && (
              <div className="mt-3 border rounded-lg overflow-hidden">
                <iframe
                  src={norm.url}
                  width="100%"
                  height="500px"
                  className="border-none"
                  title={norm.name}
                ></iframe>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
