import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Slider } from '../components/Slider';

function AlumniRegistrationForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const [yearOfPassing, setYearOfPassing] = useState("");
    const [emailError, setEmailError] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [occupation, setOccupation] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [afterDiploma, setAfterDiploma] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setEmail(email);
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError("");
        }
    };

    return (
        <>
            <Slider />
            <div className="header-parent sticky top-0">
                {/* Header can be included here if needed */}
            </div>
            <br />
            <div className="   max-w-[80%] mx-auto p-4 " method="post" action="https://gpsakoli.ac.in/public/api/connection?action=insertAlumniDetails" onSubmit={handleSubmit}>
                <h1 className="heading text-center">Alumni <span className="heading-red">Association</span></h1>
                <br></br>
                <h1 className="heading heading-top-left-line">Photo Gallery </h1>
                <p className="text-left">Will be updated soon !!</p>
            </div>
        </>
    );
}

export default AlumniRegistrationForm;
