import React, { useState } from "react";
import { Slider } from "../components/Slider";

function AlumniRegistrationForm() {
    // Form state
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        whatsappNumber: "",
        yearOfPassing: "",
        dateOfBirth: "",
        occupation: "",
        address: "",
        gender: "",
        afterDiploma: ""
    });

    // UI state
    const [emailError, setEmailError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState("");

    // Validate email format
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === "email") {
            setEmailError(validateEmail(value) ? "" : "Please enter a valid email address");
        }
    };

    // Handle WhatsApp number (numbers only)
    const handleWhatsappChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setFormData(prev => ({
            ...prev,
            whatsappNumber: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate email
        if (!validateEmail(formData.email)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        // Check required fields
        const requiredFields = {
            fullName: "Full Name",
            whatsappNumber: "WhatsApp Number",
            yearOfPassing: "Year of Passing",
            dateOfBirth: "Date of Birth",
            occupation: "Occupation",
            address: "Address",
            gender: "Gender",
            afterDiploma: "Life After Diploma"
        };

        const missingFields = Object.entries(requiredFields)
            .filter(([key]) => !formData[key])
            .map(([_, label]) => label);

        if (missingFields.length > 0) {
            setSubmitError(`Please fill all required fields: ${missingFields.join(', ')}`);
            return;
        }

        setIsSubmitting(true);
        setSubmitError("");

        try {
            // Submit to Google Forms
            await submitToGoogleForms();
            
            // Redirect to pre-filled form
            redirectToPrefilledForm();
            
        } catch (error) {
            console.error("Submission error:", error);
            setSubmitError("Failed to submit form. Please try again or contact support.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const submitToGoogleForms = () => {
        return new Promise((resolve) => {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.name = "hidden-iframe";
            document.body.appendChild(iframe);

            const form = document.createElement("form");
            form.action = "https://docs.google.com/forms/d/e/1FAIpQLScQKz0g5NyTdn_a60zUGH3gCbz-lfdpl9KvN4yfG0mAaQZzmQ/formResponse";
            form.method = "POST";
            form.target = "hidden-iframe";
            form.style.display = "none";

            const addField = (name, value) => {
                if (!value) return;
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = name;
                input.value = value;
                form.appendChild(input);
            };

            // Add all form fields
            addField("entry.2005620554", formData.fullName);
            addField("entry.1045781291", formData.email);
            addField("entry.1166974658", formData.whatsappNumber);
            addField("entry.1065046570", formData.yearOfPassing);
            addField("entry.839337160", formData.dateOfBirth);
            addField("entry.1979554182", formData.occupation);
            addField("entry.2140771339", formData.gender);
            addField("entry.1415820232", formData.address);
            addField("entry.1021095813", formData.afterDiploma);

            document.body.appendChild(form);
            form.submit();

            // Cleanup
            setTimeout(() => {
                document.body.removeChild(form);
                document.body.removeChild(iframe);
                resolve();
            }, 2000);
        });
    };

    const redirectToPrefilledForm = () => {
        const baseUrl = "https://docs.google.com/forms/d/e/1FAIpQLScQKz0g5NyTdn_a60zUGH3gCbz-lfdpl9KvN4yfG0mAaQZzmQ/viewform";
        const params = new URLSearchParams();
        
        params.append("entry.2005620554", formData.fullName);
        params.append("entry.1045781291", formData.email);
        params.append("entry.1166974658", formData.whatsappNumber);
        params.append("entry.1065046570", formData.yearOfPassing);
        params.append("entry.839337160", formData.dateOfBirth);
        params.append("entry.1979554182", formData.occupation);
        params.append("entry.2140771339", formData.gender);
        params.append("entry.1415820232", formData.address);
        params.append("entry.1021095813", formData.afterDiploma);
        
        // Open in new tab
        window.open(`${baseUrl}?${params.toString()}`, '_blank');
    };

    return (
        <>
            <Slider />
            <div className="header-parent sticky top-0"></div>
            <br />
            
            <form className="max-w-[80%] mx-auto p-4 text-left" onSubmit={handleSubmit}>
                <h1 className="heading text-center">
                    About <span className="heading-red">Alumni</span>
                </h1>
                <h1 className="heading heading-top-left-line">Alumni Registration</h1>

                {/* Name Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Full Name *</label>
                    <input
                        required
                        className="form-input"
                        type="text"
                        name="fullName"
                        placeholder="Enter your full name"
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                </div>

                {/* Email Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Email *</label>
                    <input
                        className={`form-input ${emailError ? "border-red-500" : ""}`}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                </div>

                {/* WhatsApp Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">WhatsApp Number *</label>
                    <input
                        required
                        className="form-input"
                        type="tel"
                        name="whatsappNumber"
                        placeholder="Enter WhatsApp number with country code"
                        value={formData.whatsappNumber}
                        onChange={handleWhatsappChange}
                        minLength="10"
                        maxLength="15"
                    />
                    <p className="text-gray-500 text-sm mt-1">Numbers only, include country code</p>
                </div>

                {/* Year of Passing */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Year of Passing *</label>
                    <input
                        required
                        className="form-input"
                        type="number"
                        name="yearOfPassing"
                        placeholder="YYYY"
                        min="1950"
                        max={new Date().getFullYear()}
                        value={formData.yearOfPassing}
                        onChange={handleChange}
                    />
                </div>

                {/* Date of Birth */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Date of Birth *</label>
                    <input
                        required
                        className="form-input"
                        type="date"
                        name="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        max={new Date().toISOString().split('T')[0]}
                    />
                </div>

                {/* Occupation */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Current Occupation *</label>
                    <input
                        required
                        className="form-input"
                        type="text"
                        name="occupation"
                        placeholder="Your current profession"
                        value={formData.occupation}
                        onChange={handleChange}
                    />
                </div>

                {/* Gender */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Gender *</label>
                    <div className="flex items-center space-x-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="gender"
                                value="Male"
                                checked={formData.gender === "Male"}
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Male</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="gender"
                                value="Female"
                                checked={formData.gender === "Female"}
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Female</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="gender"
                                value="Other"
                                checked={formData.gender === "Other"}
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Other</span>
                        </label>
                    </div>
                </div>

                {/* Address */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Current Address *</label>
                    <textarea
                        required
                        className="form-input"
                        name="address"
                        placeholder="Your complete address"
                        rows="3"
                        value={formData.address}
                        onChange={handleChange}
                    ></textarea>
                </div>

                {/* After Diploma */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Life After Diploma *</label>
                    <textarea
                        required
                        className="form-input"
                        name="afterDiploma"
                        placeholder="What you did after completing your diploma (higher studies, jobs, etc.)"
                        rows="4"
                        value={formData.afterDiploma}
                        onChange={handleChange}
                    ></textarea>
                </div>

                {/* Error Message */}
                {submitError && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {submitError}
                    </div>
                )}

                {/* Submit Button */}
                <div className="flex justify-center">
                    <button
                        type="submit"
                        className={`bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-lg transition duration-200 ${(emailError || isSubmitting) ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg"}`}
                        disabled={!!emailError || isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Submitting...
                            </>
                        ) : "Submit Registration"}
                    </button>
                </div>
            </form>
        </>
    );
}

export default AlumniRegistrationForm;