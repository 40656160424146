import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useParams } from "react-router-dom";

export default function DeptResultAnalysis() {
  const { branch } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // 📌 Different ResultAnalysiss for each branch
  const ResultAnalysisData = {
    computer: [
      { name: "Odd  Sem ResultAnalysis - 2021-22", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//RA%20ALL%20SEM%20%20WINTER%2023.pdf" },
      { name: "Even  Sem ResultAnalysis - 2021-22", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//Result%20Analysis%20%20SUMMER%2022.pdf" },
      { name: "Odd  Sem ResultAnalysis - 2022-23", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//RA%20ALL%20SEM%20%20WINTER%2023.pdf" },
      { name: "Even  Sem ResultAnalysis - 2022-23", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//RESULT%20ANALYSIS%20SUMMER23.pdf" },
      { name: "Odd  Sem ResultAnalysis - 2023-24", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//RESULT%20ANALYSIS%20WINTER24.pdf" },
      { name: "Even  Sem ResultAnalysis - 2023-24", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//RESULT%20ANALYSIS%20SUMMER24.pdf" },
      
    ],
    civil: [
    { name: "Odd  Sem ResultAnalysis - 2022-23", url: "https://example.com/computer- Sem ResultAnalysis-1.pdf" },
    { name: "Even  Sem ResultAnalysis - 2022-23", url: "https://example.com/computer- Sem ResultAnalysis-2.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2023-24", url: "https://example.com/computer- Sem ResultAnalysis-3.pdf" },
    { name: "Even  Sem ResultAnalysis - 2023-24", url: "https://example.com/computer- Sem ResultAnalysis-4.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-5.pdf" },
    { name: "Even  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-6.pdf" },
    ],
    mechanical: [
    { name: "Odd  Sem ResultAnalysis - 2022-23", url: "https://example.com/computer- Sem ResultAnalysis-1.pdf" },
    { name: "Even  Sem ResultAnalysis - 2022-23", url: "https://example.com/computer- Sem ResultAnalysis-2.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2023-24", url: "https://example.com/computer- Sem ResultAnalysis-3.pdf" },
    { name: "Even  Sem ResultAnalysis - 2023-24", url: "https://example.com/computer- Sem ResultAnalysis-4.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-5.pdf" },
    { name: "Even  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-6.pdf" },
    ],
    electrical: [
    { name: "Odd  Sem ResultAnalysis - 2022-23", url: "https://example.com/computer- Sem ResultAnalysis-1.pdf" },
    { name: "Even  Sem ResultAnalysis - 2022-23", url: "https://example.com/computer- Sem ResultAnalysis-2.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2023-24", url: "https://n,example.com/computer- Sem ResultAnalysis-3.pdf" },
    { name: "Even  Sem ResultAnalysis - 2023-24", url: "https://example.com/computer- Sem ResultAnalysis-4.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-5.pdf" },
    { name: "Even  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-6.pdf" },
    ],
    electronics: [
    { name: "Odd  Sem ResultAnalysis - 2022-23", url: "https://gpsakoli.ac.in//public/api/assets/ResultAnalysis_PDFs/electronics/RESULT%20ANALYSIS%20WINTER%202022%20Ejodd.pdf" },
    { name: "Even  Sem ResultAnalysis - 2022-23", url: "https://gpsakoli.ac.in//public/api/assets/ResultAnalysis_PDFs/electronics/RESULT%20ANALYSIS%20SUMMER%202023%20EjEven.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2023-24", url: "https://gpsakoli.ac.in//public/api/assets/ResultAnalysis_PDFs/electronics/RESULT%20ANALYSIS%20WINTER%202023%20ejodd.pdf" },
    { name: "Even  Sem ResultAnalysis - 2023-24", url: "https://gpsakoli.ac.in//public/api/assets/ResultAnalysis_PDFs/electronics/RESULT%20ANALYSIS%20SUMMER%202024%20%20ej.pdf" },
    { name: "Odd  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-5.pdf" },
    { name: "Even  Sem ResultAnalysis - 2024-25", url: "https://example.com/computer- Sem ResultAnalysis-6.pdf" },
    ],
  };

  const branchResultAnalysis = ResultAnalysisData[branch];

  return (
    <div >
             <h1 className="heading heading-top-left-line">ResultAnalysis</h1>  <br></br>


      {branchResultAnalysis ? (
        // 📌 Display two  Sem ResultAnalysisesters per row
        [0, 2, 4].map((startIndex) => (
          <div key={startIndex} className="flex flex-wrap -mx-2 mb-4">
            {[0, 1].map((offset) => {
              const index = startIndex + offset;
              if (!branchResultAnalysis[index]) return null;
              return (
                <div key={index} className="w-full md:w-1/2 px-2">
                  <div
                    className="p-4 shadow-md"
                    style={{
                      background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                    }}
                  >
                    <button
                      onClick={() => togglePDF(index)}
                      className="w-full flex justify-between items-center text-lg font- Sem ResultAnalysisibold text-white hover:underline focus:outline-none"
                    >
                      <span>{branchResultAnalysis[index].name}</span>
                      {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                    </button>

                    {openIndex === index && (
                      <div className="mt-3 border rounded-lg overflow-hidden">
                        <iframe
                          src={branchResultAnalysis[index].url}
                          width="100%"
                          height="500px"
                          className="border-none"
                          title={branchResultAnalysis[index].name}
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      ) : (
        <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
        No ResultAnalysis data available for this branch !!
      </p></p>
      )}
    </div>
  );
}
