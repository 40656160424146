import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function DeptTimeTable() {
  const { branch } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const deptTimeTableData = {  // Corrected variable name for consistency
    computer: [
		{
			name: "Nsemble Technologies Pvt Ltd. Nagpur",
			activity: "-",
			subject: "-",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/MOUS_CM.pdf", // Replace with actual URL
		  },

		  {
			name: "Anorg Technologies Pvt. Ltd, Gondia",
			activity: "-",
			subject: "-",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//mou1.pdf", // Replace with actual URL
		  },

		  {
			name: "Sumago Infotech Pvt Ltd. Nashik",
			activity: "-",
			subject: "-",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//mou2.pdf", // Replace with actual URL
		  },
		  
    ],
    civil: [
		{
			name: "HIXAA Technologies Pvt. Ltd. Nagpur",
			activity: "Hands-on Training and expert lecture",
			subject: "Embedded System",
			url: "path_to_pdf_file_1.pdf", // Replace with actual URL
		  },
		  {
			name: "Informatrix IT Solution Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture, Hands-on Training",
			subject: "Electronic Communication",
			url: "path_to_pdf_file_2.pdf", // Replace with actual URL
		  },
		  {
			name: "IT-NetworkZ Infosystems Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture",
			subject: "Networking and Computer Hardware",
			url: "path_to_pdf_file_3.pdf", // Replace with actual URL
		  },
		  {
			name: "Tecxtra Private Limited, Nagpur",
			activity: "Hands-on Training, Expert Lecture",
			subject: "Embedded System, Microcontroller",
			url: "path_to_pdf_file_4.pdf", // Replace with actual URL
		  },
		  {
			name: "BM Cables, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Electronic Communication",
			url: "path_to_pdf_file_5.pdf", // Replace with actual URL
		  },
		  {
			name: "V-Soft Computer System, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Networking and Computer Hardware",
			url: "path_to_pdf_file_6.pdf", // Replace with actual URL
		  },
    ],
    mechanical: [
		{
			name: "HIXAA Technologies Pvt. Ltd. Nagpur",
			activity: "Hands-on Training and expert lecture",
			subject: "Embedded System",
			url: "path_to_pdf_file_1.pdf", // Replace with actual URL
		  },
		  {
			name: "Informatrix IT Solution Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture, Hands-on Training",
			subject: "Electronic Communication",
			url: "path_to_pdf_file_2.pdf", // Replace with actual URL
		  },
		  {
			name: "IT-NetworkZ Infosystems Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture",
			subject: "Networking and Computer Hardware",
			url: "path_to_pdf_file_3.pdf", // Replace with actual URL
		  },
		  {
			name: "Tecxtra Private Limited, Nagpur",
			activity: "Hands-on Training, Expert Lecture",
			subject: "Embedded System, Microcontroller",
			url: "path_to_pdf_file_4.pdf", // Replace with actual URL
		  },
		  {
			name: "BM Cables, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Electronic Communication",
			url: "path_to_pdf_file_5.pdf", // Replace with actual URL
		  },
		  {
			name: "V-Soft Computer System, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Networking and Computer Hardware",
			url: "path_to_pdf_file_6.pdf", // Replace with actual URL
		  },
    ],
    electrical: [
		{
			name: "HIXAA Technologies Pvt. Ltd. Nagpur",
			activity: "Hands-on Training and expert lecture",
			subject: "Embedded System",
			url: "path_to_pdf_file_1.pdf", // Replace with actual URL
		  },
		  {
			name: "Informatrix IT Solution Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture, Hands-on Training",
			subject: "Electronic Communication",
			url: "path_to_pdf_file_2.pdf", // Replace with actual URL
		  },
		  {
			name: "IT-NetworkZ Infosystems Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture",
			subject: "Networking and Computer Hardware",
			url: "path_to_pdf_file_3.pdf", // Replace with actual URL
		  },
		  {
			name: "Tecxtra Private Limited, Nagpur",
			activity: "Hands-on Training, Expert Lecture",
			subject: "Embedded System, Microcontroller",
			url: "path_to_pdf_file_4.pdf", // Replace with actual URL
		  },
		  {
			name: "BM Cables, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Electronic Communication",
			url: "path_to_pdf_file_5.pdf", // Replace with actual URL
		  },
		  {
			name: "V-Soft Computer System, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Networking and Computer Hardware",
			url: "path_to_pdf_file_6.pdf", // Replace with actual URL
		  },
    ],
    electronics: [
		{
			name: "HIXAA Technologies Pvt. Ltd. Nagpur",
			activity: "Hands-on Training and expert lecture",
			subject: "Embedded System",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/hixaa.pdf", // Replace with actual URL
		  },
		  {
			name: "Informatrix IT Solution Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture, Hands-on Training",
			subject: "Electronic Communication",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/MOU%20GPSakoli%20Informatrix.pdf", // Replace with actual URL
		  },
		  {
			name: "IT-NetworkZ Infosystems Pvt.Ltd., Nagpur",
			activity: "Internship, Expert Lecture",
			subject: "Networking and Computer Hardware",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/IT%20NETWORZ%20INFOSYSTEM.pdf", // Replace with actual URL
		  },
		  {
			name: "Tecxtra Private Limited, Nagpur",
			activity: "Hands-on Training, Expert Lecture",
			subject: "Embedded System, Microcontroller",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/TECXTRA%20Pvt%20ltd%20Nagpur.pdf", // Replace with actual URL
		  },
		  {
			name: "BM Cables, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Electronic Communication",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/BM%20CABLES%20SAKOLI.pdf", // Replace with actual URL
		  },
		  {
			name: "V-Soft Computer System, Sakoli",
			activity: "Internship, Industrial Visit",
			subject: "Networking and Computer Hardware",
			url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/V%20Soft%20Sakoli.pdf", // Replace with actual URL
		  },
    ],
  };


  const mouData = deptTimeTableData[branch] || []; // Access data based on branch

  return (
    <>
              <h1 className="heading heading-top-left-line">MOU's</h1>


      <div className="mt-5">
        {mouData.length > 0 ? ( // Conditionally render the table
          <table className="table-auto border-collapse border border-gray-300 w-full">
            <thead>
              <tr className="bg-red-600">
                <th className="text-center border border-gray-300 px-4 py-2">Sr No.</th>
                <th className="text-center border border-gray-300 px-4 py-2">Name of Industries</th>
                <th className="text-center border border-gray-300 px-4 py-2">Activity done under MOU</th>
                <th className="text-center border border-gray-300 px-4 py-2">Subject related to</th>
                <th className="text-center border border-gray-300 px-4 py-2">MOU's Document</th>
              </tr>
            </thead>
            <tbody>
              {mouData.map((mou, index) => (
                <tr key={index}>
                  <td className="text-center border border-gray-300 px-4 py-2">{index + 1}</td>
                  <td className="border border-gray-300 px-4 py-2">{mou.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{mou.activity}</td>
                  <td className="border border-gray-300 px-4 py-2">{mou.subject}</td>
				  <td className="text-center border border-gray-300 px-4 py-2">
                  {mou.url && (
                    <a
                      href={mou.url}
                      target="_blank"  // Open in a new tab
                      rel="noopener noreferrer" // Crucial for security
                      className="text-blue-600 hover:underline focus:outline-none"
                    >
                      View PDF
                    </a>
                  )}
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
			<p><p className="text-red-500 text-2xl font-bold text-center mt-4">
			No MOU's data available for this branch !!
		  </p></p>
        )}
      </div>
    </>
  );
}