import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useParams } from "react-router-dom";

// 📌 Different Portfolio PDFs for each branch with direct years
const portfolioData = {
  computer: [
    { year: "2024-2025", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//Portfolio%202024-25.pdf" },
  ],
  civil: [
    { year: "2022-2023", url: "https://gpsakoli.ac.in/portfolio/civil/portfolio2023.pdf" },
    { year: "2021-2022", url: "https://gpsakoli.ac.in/portfolio/civil/portfolio2022.pdf" },
    { year: "2020-2021", url: "https://gpsakoli.ac.in/portfolio/civil/portfolio2021.pdf" },
  ],
  mechanical: [
    { year: "2022-2023", url: "https://gpsakoli.ac.in/portfolio/mechanical/portfolio2023.pdf" },
    { year: "2021-2022", url: "https://gpsakoli.ac.in/portfolio/mechanical/portfolio2022.pdf" },
    { year: "2020-2021", url: "https://gpsakoli.ac.in/portfolio/mechanical/portfolio2021.pdf" },
  ],
  electrical: [
    { year: "2022-2023", url: "https://gpsakoli.ac.in/portfolio/electrical/portfolio2023.pdf" },
    { year: "2021-2022", url: "https://gpsakoli.ac.in/public/api/assets/DeptPortfolio_PDFs/electrical/2021ee.pdf" },
    { year: "2020-2021", url: "https://gpsakoli.ac.in/portfolio/electrical/portfolio2021.pdf" },
  ],
  electronics: [
    { year: "2022-2023", url: "https://gpsakoli.ac.in/portfolio/electronics/portfolio2023.pdf" },
    { year: "2021-2022", url: "https://gpsakoli.ac.in/portfolio/electronics/portfolio2022.pdf" },
    { year: "2020-2021", url: "https://gpsakoli.ac.in/portfolio/electronics/portfolio2021.pdf" },
  ],
};

export const DeptPortfolio = () => {
  const { branch } = useParams(); // Get the branch from the URL
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Get the specific portfolio data for the branch
  const branchData = portfolioData[branch];

  return (
    <>
      <h1 className="heading heading-top-left-line">Department Portfolio </h1>

      {branchData ? (
        <div className="portfolio">
          {branchData.map((item, index) => (
            <div key={index} className="portfolio-item mb-4">
              <div
                className="p-4 shadow-md"
                style={{
                  background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                }}
              >
                <button
                  onClick={() => togglePDF(index)}
                  className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                >
                  <span>{item.year}</span>
                  {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                </button>

                {openIndex === index && (
                  <div className="mt-3 border rounded-lg overflow-hidden">
                    <iframe
                      src={item.url}
                      width="100%"
                      height="500px"
                      className="border-none"
                      title={item.year}
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
        No Portfolio data available for this branch !!
      </p></p>
      )}
    </>
  );
};
