import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Lab from "../../images/cm-lab.jpg"; // Default image for lab
import c1 from "../../images/Dept Laboratries/civil/c1.jpg";
import e1 from "../../images/Dept Laboratries/ee/e1.jpg";
import e2 from "../../images/Dept Laboratries/ee/e2.jpg";
import a1 from "../../images/Dept Laboratries/applied mehanics/a1.jpg";
import me1 from "../../images/Dept Laboratries/me/me2.jpg";
import me2 from "../../images/Dept Laboratries/me/me3.jpg";
import me3 from "../../images/Dept Laboratries/me/me1.jpg";
import s1 from "../../images/Dept Laboratries/Science and humnities/s1.jpg";
import s2 from "../../images/Dept Laboratries/Science and humnities/s2.jpg";
import s3 from "../../images/Dept Laboratries/Science and humnities/s3.jpg";
import w1 from "../../images/Dept Laboratries/workshop/w1.jpg";
import w2 from "../../images/Dept Laboratries/workshop/w2.jpg";
import w3 from "../../images/Dept Laboratries/workshop/w3.jpg";
import GalleryImage1  from "../../images/Dept Laboratries/applied mehanics/a1.jpg";
import cm7 from "../../images/Dept Laboratries/cm/cm7.jpg";
import cm2 from "../../images/Dept Laboratries/cm/cm2.jpg";
import cm3 from "../../images/Dept Laboratries/cm/cm3.jpg";
import cm4 from "../../images/Dept Laboratries/cm/cm4.jpg";
import cm5 from "../../images/Dept Laboratries/cm/cm5.jpg";
import cm6 from "../../images/Dept Laboratries/cm/cm6.jpg";

export default function DeptLaboratory(props) {
  const { branch } = useParams();

  const deptData = {
      computer: {
      labs: [
        { name: "Database Lab", majorEquipments: "10-PCs : Intel(R) Core(TM) i5-8400 CPU @ 2.80GHz-2.81GHz, 8GB RAM ", cost:<center>₹ 6,00,000</center> , area: " 6m x 8.20m ", Coordinator:" Mr. A.A. Bajpayee " },
        { name: "Network Lab", majorEquipments: "Motherboard Kit, Networ Rack, Old PC, Hard Disk, Pinter, LAN Cables, Switch, Routers..", cost: <center>₹ 70,000</center>, area: "6m x 8.20m", Coordinator:" Mr. V.B. Khobragade " },
        { name: "Software Lab", majorEquipments: "10-PCs : Intel(R) Core(TM) i5-8400 CPU @ 2.80GHz-2.81GHz, 8GB RAM" , cost: <center>₹ 6,00,000</center>, area: "4.75m x 8.30m", Coordinator:" Mr. A.A. Bajpayee " },
        { name: "Project Lab", majorEquipments: "21-PCs : Intel(R) Core(TM) i5-12500 CPU  3.00GHz, 16GB RAM", cost: <center> ₹  18,90,000</center>, area: "4.75m x 8.30m", Coordinator:" Mr. P.B. Lahoti " },
        { name: "Programming Lab", majorEquipments: "21-PCs : Intel(R) Core(TM) i5-12500 CPU  3.00GHz, 16GB RAM", cost: <center> ₹ 18,90,000</center>, area: "4.75m x 8.30m", Coordinator:" Mr. P.B. Lahoti" },
        { name: "Computer Center", majorEquipments: "10-PCs : Intel(R) Core(TM) i5-8400 CPU @ 2.80GHz-2.81GHz, 8GB RAM ", cost:<center>₹ 6,00,000</center> , area: " 6m x 8.20m ", Coordinator:"Mr. V.B. Khobragade " },
      ],
      gallery: [cm3, cm7, cm5, cm2, cm4, cm6],
      captions: ["Database Lab", "Network Lab", "Software Lab", "Project Lab", "Programming Lab ","Computer Center" ], // Customizable captions
    },

      civil: {
      labs: [
        { name: "Hydraulics Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 13.70 meter" },
        { name: "Computer Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 6.40 meter" },
        { name: "Concrete Technology & Geotechnical Engineering Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 15.30 meter" },
        { name: "Mechanics of Structure Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 11.25 meter" },
      ],
      gallery: [c1],
      captions: ["Hydraulics Lab "],
    },

      mechanical: {
      labs: [
        { name: "Refrigeration & Air Conditioning Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 12.27 meter" },
        { name: "Fluid Mechanics & Machinery Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 13.75 meter" },
        { name: "Automobile Lab & Thermal & Power Engineering", majorEquipments: "-", cost: "In Rupees", area: "8.20m x 15.25 meter" },
        { name: "CAD Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 6.40 meter" },
        { name: "Metrology & Quality Control Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 10.70 meter" },
        { name: "Mechanical Engineering Materials Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 10.70 meter" },
      ],
      gallery: [me1, me2, me3],
      captions: ["Refrigeration Lab ", "Fluid Mechanics Lab ", "Automobile Lab "],
    },

      electrical: {
      labs: [
        { name: "Computer Lab", majorEquipments: "-", cost: "In Rupees", area: "8.20m x 9.27 meter" },
        { name: "Basic Electrical & Circuit & Network Lab", majorEquipments: "-", cost: "In Rupees", area: "8.30m x 9.27 meter" },
        { name: "Measurement & Workshop Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 12.55 meter" },
        { name: "MODROB & Machine Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 18.35 meter" },
      ],
      gallery: [e1, e2],
      captions: ["Basic Electrical Lab ", "Measurement & Workshop Lab "],
    },

      workshop: {
      labs: [
        { name: "Workshop Lab", majorEquipments: "-", cost: "In Rupees", area: "6m x 8.20 meter" },
      ],
      gallery: [w1, w2, w3],
      captions: ["Workshop Lab  1", "Workshop Lab  2", "Workshop Lab  "],
    },

      applied_mechanics: {
      labs: [
        { name: "Applied Mechanics Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 11.25 meter" },
      ],
      gallery: [a1],
      captions: ["Applied Mechanics Lab "],
    },

      science_humanities: {
      labs: [
        { name: "Physics Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 18.80 meter" },
        { name: "Chemistry Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 20.30 meter" },
        { name: "Language Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25 x 6.50 meter" },
      ],
      gallery: [s1, s2, s3],
      captions: ["Physics Lab ", "Chemistry Lab ", "Language Lab "],
    },

    electronics: {
      labs: [
        { name: "Basic Electronics & Workshop", majorEquipments: "4", cost: "₹ 282417", area: "8.23m x 8 meter", },
        { name: "Digital Electronics & Microcontroller Lab", majorEquipments: "6", cost: "₹ 576414", area: "8.23m x 7.55 meter" },
        { name: "Instrumentation and Control Lab", majorEquipments: "5", cost: "₹ 524892", area: "8.23m x 7.55 meter" },
        { name: "Electronics Communication Lab", majorEquipments: "7", cost: " ₹ 5064802", area: "8.23m x 10.75 meter" },
        { name: "Analog & Power Electronics Lab", majorEquipments: "4", cost: "₹ 282417", area: "6m x 8.20 meter" },
        { name: "Electronics Software & Project Lab", majorEquipments: "4", cost: "₹ 842915", area: "8.23m x 6.20 meter" },
      ],
      gallery: [GalleryImage1, GalleryImage1, GalleryImage1],
      captions: [],
    },
  };

  const branchData = deptData[branch];

  if (!branchData) {
    return (
      <>
        <h1 className="heading heading-top-left-line">Laboratories</h1>
        <p>No laboratory information available for this branch.</p>
      </>
    );
  };

  return (
    <>
      <h1 className="heading heading-top-left-line">Laboratories</h1>

      {/* Image Gallery */}
      <div className="image-gallery mt-5" style={{ overflow: "hidden" }}>
      <div className="gallery-container" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
  {branchData.gallery.map((image, index) => {
    // Start a new row every 3 images
    if (index % 3 === 0) {
      return (
        <div key={`row-${index}`} style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {branchData.gallery.slice(index, index + 3).map((img, i) => (
            <div key={i} className="gallery-item" style={{
              flex: "1 0 200px",
              margin: "10px",
              maxWidth: "300px"
            }}>
              <img src={img} alt={`Gallery ${index + i + 1}`} style={{
                display: "block",
                width: "100%",
                height: "auto"
              }} />
              <div className="gallery-caption" style={{ textAlign: "center", marginTop: "8px" }}>
                <h2 className="event-title" style={{ color: "black", fontWeight: "bold" }}>
                  {branchData.captions[index + i] || `Gallery ${index + i + 1}`}
                </h2>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null; // Prevents extra renders
  })}
</div>

      </div>

      {/* Table Section */}
      <div className="maindiv1">
        <div className="mt-5">
          <table border="1px">
            <thead>
              <tr>
                <th className="text-center">Sr No.</th>
                <th>Name of Laboratories</th>
                <th className="text-center">Major Equipments</th>
                <th>Approx. cost of one equipment in rupees</th>
                <th>Area of Lab</th>
                <th>Lab Co-ordinator</th>
              </tr>
            </thead>
            <tbody>
              {branchData.labs.map((lab, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{lab.name}</td>
                  <td className="text-center">{lab.majorEquipments}</td>
                  <td>{lab.cost}</td>
                  <td>{lab.area}</td>
                  <td>{lab.Coordinator}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
