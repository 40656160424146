import React, { useState } from "react";
import { Slider } from "../components/Slider";
import ModalImage from "react-modal-image";

// Import images explicitly
import InductionProgramImage from "../images/news/N1.jpg";
import GoKartImage from "../images/news/7May2022.jpeg";
import InductionProgram2 from "../images/news/InductionProgram21sep22.jpeg";
import HarGharTiranga from "../images/news/t12aug22.jpeg";
import Yojna from "../images/news/Jaldharohar8Sep22.jpeg";
import Project from "../images/news/deshokti.jpg";



export default function GPSInNews() {
  const [newses, setNewses] = useState([
    {
      news_title: "Entrepreneurship Development Workshop at GP Sakoli",
      news_article_image: InductionProgramImage,
      date_of_event: "March 19, 2024",
    },

    {
        news_title: "Jal Dharohar Yojna Perfrom by Gp Sakoli",
        news_article_image: Yojna,
        date_of_event: "August 12, 2022",
      },

   
    {
      news_title: "Induction Program",
      news_article_image: InductionProgram2,
      date_of_event: "September 21, 2022",
    },

    {
        news_title: "Students of Government Polytechnic Sakoli have developed an electric 'Go Kart' vehicle",
        news_article_image: GoKartImage,
        date_of_event: "May 07, 2022",
      },
 
    {
      news_title: "Make the 'Har Ghar Tiranga' initiative successful: Principal S. P. Lobhade",
      news_article_image: HarGharTiranga,
      date_of_event: "August 12, 2022",
    },

    {
      news_title: "Deshonnati, Project Competition ",
      news_article_image: Project,
      date_of_event: "August 12, 2022",
    },



    
  ]);

  return (
    <>
      <Slider />
      <div className="text-center py-8">
        <h1 className="text-4xl font-bold">
          <span className="text-red-600">GPS</span> In News
        </h1>
      </div>

      <div className="flex flex-wrap justify-center gap-6 px-4">
        {newses.map((news, index) => (
          <div
            key={index}
            className="w-80 bg-white shadow-lg rounded-lg overflow-hidden transform transition-all hover:scale-105"
          >
            <div className="p-4 text-center">
              <h2 className="text-lg font-semibold">{news.news_title}</h2>
              <p className="text-gray-500 text-sm">{news.date_of_event}</p>
            </div>

            <div className="news-image-container">
              <ModalImage
                className="w-full h-full object-cover"
                small={news.news_article_image}
                large={news.news_article_image}
                alt={news.news_title}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
