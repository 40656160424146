
import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi"; // Import icons from react-icons

export const RightToInformation = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const norms = [
    
    {
      name: "Right To Information Act Information  ",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//rtiact-marathi%20(2).pdf",
    },
  ];

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="heading heading-top-left-line"> Right To Information</h1>
     

			
			<div className="mt-5">
      
        <table border="1px">
          <thead>
            <tr>
              <td>Sr No.</td>
              <td>Name of Committee Members</td>
              <td>Designation</td>
			  <td>Contacts</td>
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              <td>Mr. S. P. Lambhade</td>
              <td>Appealing Officer </td>
              <td>9423433588</td>
            </tr>
            <tr>
              <td>02</td>
              <td>Mr. L. K. Kothwar</td>
              <td>Information Officer</td>
              <td>7588888838</td>
            </tr>
            <tr>
              <td>03</td>
              <td>	Mr. G. P. Patle </td>
              <td> Asst. Information Officer</td>
              <td>9881988198</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>

      <br></br>
      <ul className="space-y-3">
        {norms.map((norm, index) => (
          <li
            key={index}
            className="p-4 shadow-md "
            style={{
              background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
            }}
          >
            <button
              onClick={() => togglePDF(index)}
              className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
            >
              <span> {norm.name}</span>
              {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
            </button>

            {openIndex === index && (
              <div className="mt-3 border rounded-lg overflow-hidden">
                <iframe
                  src={norm.url}
                  width="100%"
                  height="500px"
                  className="border-none"
                  title={norm.name}
                ></iframe>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
