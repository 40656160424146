import React, { useState, useEffect, Suspense, lazy } from 'react';
import Header from '../components/Header';
import SideMenu from "../components/SideMenu";
import DeptAbout from '../components/department-components/DeptAbout';
import DeptResultAnalysis from '../components/department-components/DeptResultAnalysis';
import DeptTimeTable from '../components/department-components/DeptTimeTable';
import DeptNewsLetter from '../components/department-components/DeptNewsLetter';
import DeptCurriculum from '../components/department-components/DeptCurriculum';
import DeptLaboratory from '../components/department-components/DeptLaboratory';
import { DeptPortfolio } from '../components/department-components/DeptPortfolio';
import DeptIRG from '../components/department-components/DeptIRG';
import DigitalLibrary from '../components/student-corner-components/DigitalLibrary';
import { useParams } from 'react-router-dom';
import DeptEventAnalysis from '../components/department-components/DeptEventAnalysis';
import DeptMous from '../components/department-components/DeptMous';

import image1 from '../images/cm s1.jpg';

import image3 from '../images/cm s3.jpg';
import image4 from '../images/cm s5.jpg';
import image11 from '../images/cms6.jpg';
import image12 from '../images/cm s7.jpg';

import image7 from '../images/Slider/electronics/s4.jpg';
import image8 from '../images/Slider/electronics/s1.jpg';
import image5 from '../images/Slider/electronics/s3.jpg';
import image6 from '../images/Slider/electronics/s5.jpg';

const DeptFaculty = lazy(() => import('../components/department-components/DeptFaculty'));

export default function Department() {
    const [labsData, setLabsData] = useState([]);
    const [mousData, setMousData] = useState([]);
    const [supportingStaffData, setSupportingStaffData] = useState([]);
    const [deptPortfolioData, setDeptPortfolioData] = useState([]);
    const [curriculumData, setCurriculumData] = useState([]);
    const [irg, setIRGData] = useState([]);
    const [timeTable, setTimeTable] = useState([]);
    const [resultAnalysis, setResultAnalysis] = useState([]);
    const [eventAnalysis, setEventAnalysis] = useState([]);
    const deptContent = useParams();

    const departmentImages = {
        computer: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' },
            { src: image11, caption: 'Ashok Laylands Toll Plaza, Sendurwafa. On 2nd March 2024' },
            { src: image12, caption: 'Online Expert Lecture Organised today on "Latest Technologies for Career" ' }
        
        ],
        civil: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        electrical: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
       
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        mechanical: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
         
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        electronics: [
           { src: image8, caption: '' },
            { src: image5, caption: '' },
            { src: image6, caption: '' },
            { src: image7, caption: '' }
        ],
        science_humanities: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        workshop: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
     
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
    };

    const images = departmentImages[deptContent.branch] || departmentImages['electronics'];
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Automatically change slides every 3 seconds
    useEffect(() => {
        const intervalId = setInterval(nextSlide, 3000); // Change slide every 3 seconds
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [images.length]); // Re-run effect if the length of images changes

    // Define menu links for each branch
    const branchMenuLinks = {
        computer: [
            { name: 'About Department', path: '/dept/computer/about-department' },
            { name: 'Department Portfolio', path: '/dept/computer/department-portfolio' },
            { name: 'Digital Library', path: '/dept/computer/digital-library' },
            { name: 'Faculties', path: '/dept/computer/faculties' },
            { name: 'Result Analysis', path: '/dept/computer/result-analysis' },
            { name: 'Various Events', path: '/dept/computer/Various Events' },
            { name: 'MOUs', path: '/dept/computer/mous' },
            { name: 'Laboratories', path: '/dept/computer/laboratories' },
            { name: 'Curriculum & Syllabus', path: '/dept/computer/curriculum' },
            { name: 'Time Table', path: '/dept/computer/time-table' },
            { name: 'News Letter', path: '/dept/computer/news-letter' },
            { name: 'Internal Revenue Generation', path: '/dept/computer/internal-revenue-generation' },
        ],
        civil: [
            { name: 'About Department', path: '/dept/civil/about-department' },
            { name: 'Department Portfolio', path: '/dept/civil/department-portfolio' },
            { name: 'Digital Library', path: '/dept/civil/digital-library' },
            { name: 'Faculties', path: '/dept/civil/faculties' },
            { name: 'Result Analysis', path: '/dept/civil/result-analysis' },
            { name: 'Various Events', path: '/dept/civil/Various Events' },
            { name: 'MOUs', path: '/dept/civil/mous' },
            { name: 'Laboratories', path: '/dept/civil/laboratories' },
            { name: 'Curriculum & Syllabus', path: '/dept/civil/curriculum' },
            { name: 'Time Table', path: '/dept/civil/time-table' },
            { name: 'News Letter', path: '/dept/civil/news-letter' },
            { name: 'Internal Revenue Generation', path: '/dept/civil/internal-revenue-generation' },
        ],
        electrical: [
            { name: 'About Department', path: '/dept/electrical/about-department' },
            { name: 'Department Portfolio', path: '/dept/electrical/department-portfolio' },
            { name: 'Digital Library', path: '/dept/electrical/digital-library' },
            { name: 'Faculties', path: '/dept/electrical/faculties' },
            { name: 'Result Analysis', path: '/dept/electrical/result-analysis' },
            { name: 'Various Events', path: '/dept/electrical/Various Events' },
            { name: 'MOUs', path: '/dept/electrical/mous' },
            { name: 'Laboratories', path: '/dept/electrical/laboratories' },
            { name: 'Curriculum & Syllabus', path: '/dept/electrical/curriculum' },
            { name: 'Time Table', path: '/dept/electrical/time-table' },
            { name: 'News Letter', path: '/dept/electrical/news-letter' },
            { name: 'Internal Revenue Generation', path: '/dept/electrical/internal-revenue-generation' },
        ],
        mechanical: [
            { name: 'About Department', path: '/dept/mechanical/about-department' },
            { name: 'Department Portfolio', path: '/dept/mechanical/department-portfolio' },
            { name: 'Digital Library', path: '/dept/mechanical/digital-library' },
            { name: 'Faculties', path: '/dept/mechanical/faculties' },
            { name: 'Result Analysis', path: '/dept/mechanical/result-analysis' },
            { name: 'Various Events', path: '/dept/mechanical/Various Events' },
            { name: 'MOUs', path: '/dept/mechanical/mous' },
            { name: 'Laboratories', path: '/dept/mechanical/laboratories' },
            { name: 'Curriculum & Syllabus', path: '/dept/mechanical/curriculum' },
            { name: 'Time Table', path: '/dept/mechanical/time-table' },
            { name: 'News Letter', path: '/dept/mechanical/news-letter' },
            { name: 'Internal Revenue Generation', path: '/dept/mechanical/internal-revenue-generation' },
        ],
        electronics: [
            { name: 'About Department', path: '/dept/electronics/about-department' },
            { name: 'Department Portfolio', path: '/dept/electronics/department-portfolio' },
            { name: 'Digital Library', path: '/dept/electronics/digital-library' },
            { name: 'Faculties', path: '/dept/electronics/faculties' },
            { name: 'Result Analysis', path: '/dept/electronics/result-analysis' },
            { name: 'Various Events', path: '/dept/electronics/Various Events' },
            { name: 'MOUs', path: '/dept/electronics/mous' },
            { name: 'Laboratories', path: '/dept/electronics/laboratories' },
            { name: 'Curriculum & Syllabus', path: '/dept/electronics/curriculum' },
            { name: 'Time Table', path: '/dept/electronics/time-table' },
            { name: 'News Letter', path: '/dept/electronics/news-letter' },
            { name: 'Internal Revenue Generation', path: '/dept/electronics/internal-revenue-generation' },
        ],
        science_humanities: [
            { name: 'About Department', path: '/dept/science_humanities/about-department' },
            { name: 'Faculties', path: '/dept/science_humanities/faculties' },
            { name: 'Laboratories', path: '/dept/science_humanities/laboratories' },
            { name: 'Various Events', path: '/dept/science_humanities/Various Events' },
        ],
        workshop: [
            { name: 'About Workshop', path: '/dept/workshop/about-department' },
            { name: 'Faculties', path: '/dept/workshop/faculties' },
            { name: 'Laboratories', path: '/dept/workshop/laboratories' },
            { name: 'Curriculum & Syllabus', path: '/dept/workshop/curriculum' }
        ],
    };

    // Get the menu links for the current branch
    const deptMenuLinks = branchMenuLinks[deptContent.branch] || [];

    // Fetch data for the current branch
    useEffect(() => {
        const fetchData = async () => {
            const endpoints = [
                { key: 'labs', setter: setLabsData },
                { key: 'mouReports', setter: setMousData },
                { key: 'supporting-staff-details', setter: setSupportingStaffData },
                { key: 'dept-portfolio', setter: setDeptPortfolioData },
                { key: 'curriculum', setter: setCurriculumData },
                { key: 'irg', setter: setIRGData },
                { key: 'timeTable', setter: setTimeTable },
                { key: 'resultAnalysis', setter: setResultAnalysis },
                { key: 'eventAnalysis', setter: setEventAnalysis },
            ];

            for (const endpoint of endpoints) {
                const response = await fetch(`https://gpsakoli.ac.in/public/api/connection.php?${endpoint.key}=${deptContent.branch}`, {
                    method: "GET"
                });
                const data = await response.json();
                endpoint.setter(data.data);
            }
        };

        fetchData();
    }, [deptContent.branch]);

    // Map menu items to their respective components
    const menuComponentMap = {
        'about-department': <DeptAbout />,
        'department-portfolio': <DeptPortfolio data={deptPortfolioData} />,
        'digital-library': <DigitalLibrary />,
        'faculties': (
            <Suspense fallback={<p>Loading...</p>}>
                <DeptFaculty supportingStaff={supportingStaffData} />
            </Suspense>
        ),
        'curriculum': <DeptCurriculum curriculum={curriculumData} />,
        'result-analysis': <DeptResultAnalysis data={resultAnalysis} />,
        'Various Events': <DeptEventAnalysis data={eventAnalysis} />,
        'mous': <DeptMous MousData={mousData} />,
        'laboratories': <DeptLaboratory LabsData={labsData} />,
        'time-table': <DeptTimeTable data={timeTable} />,
        'news-letter': <DeptNewsLetter />,
        'internal-revenue-generation': <DeptIRG data={irg} />,
    };

    return (
        <>
             <Header links={deptMenuLinks} />
            <div className="slider1" style={{ position: 'relative', textAlign: 'center' }}>
                <button className="slider-button prev1" onClick={prevSlide}>❮</button>
                <img src={images[currentIndex].src} alt={`Slide ${currentIndex + 1}`} className="slide-image1" />
                <div className="image-name-container">
                    <p className="image-name">{images[currentIndex].caption}</p>
                </div>
                <button className="slider-button next1" onClick={nextSlide}>❯</button>
            </div>
    
            <div className="main-container1">
                <div className="text-center">
                    <h1 className='heading'>Department of {deptContent.branch === 'computer' ? 'Computer Technology' : deptContent.branch === 'civil' ? 'Applied Mech. & Civil Engineering' : deptContent.branch === 'electrical' ? 'Electrical Engineering' : deptContent.branch === 'mechanical' ? 'Mechanical Engineering' : deptContent.branch === 'workshop' ? 'Workshop' : deptContent.branch === 'science_humanities' ? 'Science and Humanities': deptContent.branch === 'electronics' ? 'Electronics & Tel. Comm. Engineering ' : ''}</h1>
                    <h1 className='heading heading-red'>
                        {deptContent.branch !== "electronics" && "NBA Accredited"}
                    </h1>
                </div>
    
                <div className="dynamic-container">
                    <div className="dynamic-change-container">
                        {menuComponentMap[deptContent.content]}
                    </div>
                    <SideMenu links={deptMenuLinks} />
                </div>
            </div>
        </>
    );
}